@import "src/themes/mojito/styles/index.scss";
.home-advantages {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include sm {
    flex-direction: column;
    gap: space(1.063);
    align-items: center;
  }

  @include lg {
    flex-direction: row;
  }

  &--header {
    text-align: center !important;
    a {
      display: none;
    }

    @include sm {
      text-align: left !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: space(1.5);
      a {
        display: block;
        text-align: center;
        margin-top: 20px;
        width: 250px;
      }
    }

    @include lg {
      max-width: 30%;
      text-align: left !important;
      padding-right: 5rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      a {
        display: block;
      }
    }

    h2 {
      text-align: center;
      margin-left: 1rem;
      margin-right: 1rem;

      @include sm {
        text-align: center;
        margin-left: unset !important;
        margin-right: unset !important;
        padding: 0;
      }
      @include lg {
        width: 88%;
        text-align: left;
      }
    }

    p {
      text-align: center;
      display: inline;
      font-size: 16px;

      @include sm {
        display: block;

        margin-bottom: 0 !important;
        max-width: 518px;
        text-align: center;
      }

      @include lg {
        text-align: left;
        font-size: 18px;
        margin-bottom: 16px !important;
      }

      .u-br-desktop {
        display: inline;
        @include lg {
          display: block;
        }
      }
    }
  }

  &--body {
    a {
      @include sm {
        display: none;
      }

      @include md {
        display: none;
      }
    }
  }
}

.nested-list {
  margin-top: 1rem;
}

.underlined {
  text-decoration: underline;
}
.notfound-box {
  box-sizing: unset !important;
  box-shadow: 2px 0px 6px 0px rgba(166, 166, 166, 0.25);
  padding: space(1.5) space(1) !important;
  margin-top: space(1.5) !important;
  margin-bottom: space(2.5) !important;
  @include sm {
    max-width: 528px;
    margin: 0 auto;
    padding: space(2) !important;
  }

  @include lg {
    max-width: 760px;
  }

  p {
    width: 75%;
    margin: 0 auto;
    font-size: space(1);
    line-height: space(1.5);

    @include sm {
      width: 60%;
    }

    @include lg {
      width: 50%;
      font-size: space(1.5);
      font-weight: 600;
      line-height: space(2);
    }
  }

  h2 {
    font-size: space(1.125) !important;
    font-weight: 700;
    line-height: space(1.5) !important;
    @include sm {
      max-width: 60%;
      margin: 0 auto space(1.5);
    }

    @include lg {
      max-width: 55%;
      font-size: space(1.5) !important;
      line-height: space(2) !important;
    }
  }

  .notfound-img {
    svg {
      height: 80px;
      width: 80px;

      @include sm {
        height: 160px;
        width: 160px;
      }

      @include lg {
        height: 220px;
        width: 220px;
      }
    }
  }

  .button {
    margin-top: space(1);

    @include sm {
      margin-top: space(2);
    }
  }
}

.cookies-policy-container {
  .Table th,
  .Table td {
    word-break: break-word;
    word-wrap: break-word;
    white-space: normal;
    width: 63px;
    overflow: hidden;
    padding: 0.75rem 0rem 0.75rem 0rem;

    @include sm {
      width: 100px;
      overflow: hidden;
      padding: 0.75rem 1rem 0.75rem 1rem;
    }
  }
}
